exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-performance-js": () => import("./../../../src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-pre-authorized-debit-js": () => import("./../../../src/pages/pre-authorized-debit.js" /* webpackChunkName: "component---src-pages-pre-authorized-debit-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-vppa-js": () => import("./../../../src/pages/vppa.js" /* webpackChunkName: "component---src-pages-vppa-js" */)
}

